/**
 * When given for example bg-gray-100,
 * this func returns bg-gray-200
 */
export function getDarkerColorClass(inputClass, step = 100) {
  const exceptionMapping = [
    {search: 'bg-white', replace: 'bg-gray-300'},
    {search: 'text-white', replace: 'text-gray-300'},
    {search: 'border-white', replace: 'border-gray-300'},
    {search: 'bg-black', replace: 'bg-gray-700'},
    {search: 'text-black', replace: 'text-gray-700'},
    {search: 'border-black', replace: 'border-gray-700'},
  ];

  // When inputClass equals .search, return .replace
  const foundException = exceptionMapping.find(e => e.search === inputClass);
  if (foundException)
    return foundException.replace;

  // Non Exceptions
  const maxColorValue = 900;

  let currentNumber = Number(inputClass.replace(/^\D+/g, ''));
  const newNumber = (currentNumber + step) > maxColorValue
    ? maxColorValue
    : currentNumber + step;

  const classBeginning = inputClass.replace(/[0-9]/g, '');

  return classBeginning + newNumber;
}

/**
 * Replaces something like 'text-gray-300'
 * with 'bg-gray-300' when desiredType === 'bg'
 * @param inputClass
 * @param desiredType
 * @returns {*}
 */
export function replaceColorType(inputClass, desiredType) {
  return inputClass.replace(/bg|text|border/gi, desiredType);
}